<template lang="html">
  <div>
    <b-card>
      <div class="p-3">
        <b-row>
          <b-col
            class="d-flex flex-column flex-md-row justify-content-md-between"
          >
            <span class="card-title">
              Administradores Registrados
            </span>
            <div class="d-flex">
              <z-button
                class="order-0 order-md-1 mr-3 mr-md-0"
                @click="$router.push({ name: 'adminsCreate' })"
              >
                Crear Administrador
              </z-button>
            </div>
          </b-col>
        </b-row>
        <b-row class="filter-container">
          <b-col md="4" lg="3" xl="3" class="mb-3 mb-md-0">
            <div class="search-filter d-flex flex-row align-items-center pl-3">
              <font-awesome-icon class="icon" icon="search" />
              <b-form-input
                v-model="searchText"
                class="flex-fill"
                placeholder="Busca por ID, nombre o correo"
                size="sm"
                type="search"
              />
            </div>
          </b-col>
          <b-col lg="3" class="mb-3 mb-md-0">
            <z-dropdown
              id="dropdown-role-type"
              v-model="type"
              name="Estado"
              class="d-md-none"
              :options="typeOptions"
            />
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <quick-message
              class="my-3"
              with-icon
              :show.sync="hasError"
              :message="message"
              type="error"
            />
            <z-table
              class="mt-4"
              responsive="lg"
              hover
              :items="users"
              :per-page="perPage"
              :fields="fields"
              :busy="isLoading"
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortDesc"
              @row-clicked="viewUser"
            >
              <template v-slot:table-busy>
                <div class="text-center text-danger my-2">
                  <loading-spinner />
                </div>
              </template>
              <template v-slot:empty>
                <p class="text-center py-5 my-5 font-weight-semi-bold">
                  {{
                    "No se encontraron usuarios registrados que coincidan con el filtro"
                  }}
                </p>
              </template>
              <!-- Headers -->
              <template v-slot:head(roleType)>
                <div>
                  <div class="d-lg-none">
                    Rol
                  </div>
                  <z-table-header-dropdown
                    id="dropdown-role-type"
                    v-model="type"
                    class="d-none d-lg-block"
                    :options="typeOptions"
                    name="Rol"
                  />
                </div>
              </template>
              <!-- Cells -->
              <template v-slot:cell(roleType)="{ item: user }">
                <span>
                  {{ getRoleType(user) }}
                </span>
              </template>
              <template v-slot:cell(activity)="{ item: user }">
                <router-link
                  v-if="
                    user.id == loggedUserId ||
                      $can(
                        access.userActivity.READ_OTHER_USERS_ACTIVITIES,
                        access.userActivity.moduleName
                      )
                  "
                  :to="{
                    name: 'logs',
                    query: { admin: user.id, rol: adminRole }
                  }"
                  class="text-decoration-none"
                >
                  Ver Actividad
                </router-link>
              </template>
            </z-table>
            <z-table-pagination
              :total-rows="totalRows"
              :per-page.sync="perPage"
              :current-page.sync="currentPage"
            />
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import filtering from "@/mixins/filtering";
import ZDropdown from "@zubut/common/src/components/ZDropdown";
import ZTableHeaderDropdown from "@zubut/common/src/components/ZTableHeaderDropdown";
import ZTablePagination from "@zubut/common/src/components/ZTablePagination";
import ZTable from "@zubut/common/src/components/ZTable.vue";
import RoleType from "@/constants/roles/type";
import AdminAccess from "@/constants/access/admins";
import _debounce from "lodash/debounce";
import Users from "@zubut/common/src/constants/logs/users";
import Auth from "@/app/authentication";
import Access from "@/constants/access";

export default {
  name: "Admins",

  components: {
    ZDropdown,
    ZTableHeaderDropdown,
    ZTablePagination,
    ZTable
  },

  mixins: [filtering("admins")],

  data() {
    return {
      access: Access,
      loggedUserId: Auth.getLoggedUserId(),
      isLoading: false,
      sortBy: "lastLogin",
      sortDesc: true,
      fields: [
        { key: "name", label: "Nombre" },
        { key: "email", label: "Email" },
        { key: "roleType", label: "Rol" },
        { key: "activity", label: "Actividad" }
      ],
      typeOptions: [
        { value: null, text: "Todos" },
        { value: RoleType.NUM_SUPER_ADMIN, text: RoleType.SUPER_ADMIN },
        { value: RoleType.NUM_OPERATIONS, text: RoleType.OPERATIONS },
        { value: RoleType.NUM_ACCOUNTING, text: RoleType.ACCOUNTING },
        { value: RoleType.NUM_SALES, text: RoleType.SALES },
        { value: RoleType.NUM_MARKETING, text: RoleType.MARKETING }
      ],
      hasError: false,
      message: "",
      adminRole: Users.NUM_ADMIN
    };
  },

  computed: {
    searchText: {
      get() {
        return this.filters.search ?? "";
      },
      set(val) {
        this.setFilter("search", val);
      }
    },

    type: {
      get() {
        return this.filters.type ?? null;
      },
      set(val) {
        this.setFilter("type", val);
      }
    },

    users() {
      return this.$store.getters["admin/listingAdmins"];
    }
  },

  watch: {
    currentPage: "getListing",
    perPage: "getListing"
  },

  created() {
    this.search = _debounce(() => {
      this.getListing();
    }, 600);
  },

  beforeMount() {
    this.registerListeners(this.getListing);
    this.getListing();
  },

  methods: {
    buildRequest() {
      const filter = {
        ...this.pagination,
        order: `${this.sortBy} DESC`
      };
      const where = { like: this.searchText };

      if (this.filters.type != null) {
        where.type = this.filters.type;
      }

      return { filter, where };
    },

    getListing() {
      this.isLoading = true;
      this.$store
        .dispatch("admin/getListing", this.buildRequest())
        .then(({ meta }) => {
          if (meta.skip === 0) {
            this.totalRows = meta.count;
          }
        })
        .catch(err => {
          this.hasError = true;
          this.message = err.message;
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    getRoleType(user) {
      return `${RoleType.get[user.roleType]}`;
    },

    makeFilter() {
      let where = {};
      if (this.filters.status != null && this.filters.type != null) {
        where = {
          and: [{ status: this.filters.status }, { type: this.filters.status }]
        };
      } else if (this.filters.status != null) {
        where = { status: this.filters.status };
      } else if (this.filters.type != null) {
        where = { type: this.filters.type };
      }
      return where;
    },

    viewUser(item) {
      const id = item.id;
      if (this.$can(AdminAccess.READ_ADMIN_DETAIL, AdminAccess.moduleName)) {
        this.$router.push({ name: "adminDetail", params: { id } });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.search-filter {
  cursor: text;
  background-color: $white;
  border: 1px solid $gainsboro;
  border-radius: 0.25rem;

  .icon {
    color: $nobel;
  }

  input {
    cursor: text;
    color: $nero;
    min-height: 32px;
    font-size: 12px;
    border-color: transparent;

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
}
</style>
